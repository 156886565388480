import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div>
            <div className="privacy-container">
            
                <h1>Privacy Policy</h1>
                <p>Welcome to Waffle Island! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you use our services.</p>

                <h2>1. Information We Collect</h2>
                <p>We collect various types of information in connection with the services we provide, including:</p>
                <ul>
                    <li><strong>Personal Information:</strong> Username, email address, password, referral codes.</li>
                    <li><strong>Technical Data:</strong> IP address, geolocation data, device information, previous IP addresses.</li>
                    <li><strong>Usage Data:</strong> In-game activity, chat logs, referral source, membership status, referred users.</li>
                    <li><strong>Other Relevant Information:</strong> Any other data you voluntarily provide.</li>
                </ul>

                <h2>2. How We Use Your Information</h2>
                <p>We use the information we collect to:</p>
                <ul>
                    <li>Provide and maintain our services.</li>
                    <li>Personalize your experience.</li>
                    <li>Improve our platform.</li>
                    <li>Communicate with you.</li>
                    <li>Ensure security and prevent fraud.</li>
                    <li>Comply with legal obligations.</li>
                </ul>

                <h2>3. Legal Basis for Processing</h2>
                <p>We process your data based on:</p>
                <ul>
                    <li><strong>Consent:</strong> By signing up, you consent to our collection and use of your information.</li>
                    <li><strong>Contract:</strong> Processing your data is necessary for fulfilling our contractual obligations.</li>
                    <li><strong>Legal Obligation:</strong> Compliance with legal and regulatory requirements.</li>
                    <li><strong>Legitimate Interests:</strong> To improve our services and user experience.</li>
                </ul>

                <h2>4. Data Sharing and Disclosure</h2>
                <p>We may share your information with:</p>
                <ul>
                    <li><strong>Service Providers:</strong> For operational purposes, such as hosting and payment processing.</li>
                    <li><strong>Legal Authorities:</strong> When required by law or to protect our rights.</li>
                    <li><strong>Other Users:</strong> Limited information such as username and in-game activity.</li>
                </ul>

                <h2>5. International Data Transfers</h2>
                <p>For users in the European Economic Area (EEA), your data may be transferred to, and processed in, countries outside of the EEA. We ensure that such transfers are lawful and that your data is adequately protected through standard contractual clauses, Privacy Shield certification, or other approved transfer mechanisms.</p>

                <h2>6. Your Rights</h2>
                <p>Depending on your location, you may have the following rights:</p>
                <ul>
                    <li>Request access to your data.</li>
                    <li>Correct inaccuracies in your data.</li>
                    <li>Request deletion of your data.</li>
                    <li>Opt-out of data processing and marketing communications.</li>
                    <li>Request a copy of your data in a structured format.</li>
                    <li>Object to processing of your data under certain conditions.</li>
                    <li>Request the restriction of processing of your data.</li>
                </ul>
                <p>For users in the EEA, you also have the right to:</p>
                <ul>
                    <li>Lodge a complaint with a data protection authority if you believe your rights under the GDPR have been violated.</li>
                </ul>

                <h2>7. Children's Privacy</h2>
                <p>Waffle Island is designed for children and young teens. We comply with the Children’s Online Privacy Protection Act (COPPA) and the GDPR provisions for children's data protection. We do not knowingly collect personal information from children under 13 without parental consent.</p>

                <h2>8. Data Security</h2>
                <p>We implement robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. This includes encryption, access controls, and regular security assessments.</p>

                <h2>9. Data Retention</h2>
                <p>We retain your data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>

                <h2>10. Automated Decision-Making and Profiling</h2>
                <p>We do not use automated decision-making or profiling that significantly affects users without obtaining explicit consent or ensuring such processing is necessary for the performance of a contract.</p>

                <h2>11. Changes to This Privacy Policy</h2>
                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website and, where appropriate, via email.</p>

                <h2>12. Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
                <p><strong>Email:</strong> privacy@waffleisland.co</p>
                <p><strong>Address:</strong> 123 Waffle Street, Suite 456, Virtual City, Game World</p>

            </div>
        </div>
    );
};

export default PrivacyPolicy;