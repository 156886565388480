import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import './Help.css';

const Help = () => {
    const [formData, setFormData] = useState({
        uid: '',
        email: '',
        issue: '',
        description: ''
    });
    const [responseMessage, setResponseMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await user.getIdToken();
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    uid: user.uid,
                    email: user.email || '',
                    token
                }));
            }
        });

        return () => unsubscribe();
    }, [auth]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post('/api/api/help', formData, {
                headers: {
                    Authorization: `Bearer ${formData.token}`
                }
            });
            setResponseMessage(response.data.message);
            setSubmitted(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            setResponseMessage(error.response?.data?.message || 'An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="help-container">
            <h1>Help and Support</h1><br></br>
            <p>If you need assistance, please fill out the form below and our support team will get back to you as soon as possible.<br></br> <br></br>Your request will be handled in the order it was received.</p><br></br>
            <form className="help-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input 
                        type="email" 
                        id="email" 
                        name="email" 
                        value={formData.email} 
                        onChange={handleChange} 
                        required 
                        disabled={submitted} 
                        className={submitted ? "greyed-out" : ""}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="issue">Issue</label>
                    <select 
                        id="issue" 
                        name="issue" 
                        value={formData.issue} 
                        onChange={handleChange} 
                        required
                        disabled={submitted} 
                        className={submitted ? "greyed-out" : ""}
                    >
                        <option value="">Select an issue</option>
                        <option value="login">Login Issues</option>
                        <option value="payment">Payment Issues</option>
                        <option value="bug">Report a Bug</option>
                        <option value="development">Development Request</option>
                        <option value="development">Ban Appeal</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <textarea 
                        id="description" 
                        name="description" 
                        value={formData.description} 
                        onChange={handleChange} 
                        required 
                        disabled={submitted} 
                        className={submitted ? "greyed-out" : ""}
                    ></textarea>
                </div>
                <button type="submit" disabled={loading || submitted}>Submit</button>
                {loading && <div className="loading-overlay"><div className="spinner"></div></div>}
            </form><br></br>
            {responseMessage && <p className="response-message">{responseMessage}</p>}
        </div>
    );
};

export default Help;