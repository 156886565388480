// src/pages/UserDetail.js
import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button } from '@mui/material';

const UserDetail = ({ user, open, onClose }) => {
  if (!user) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>User Info</DialogTitle>
      <DialogContent>
        <Typography>Username: {user.username}</Typography>
        <Typography>Email: {user.email}</Typography>ß
        <Typography>Time Spent: {user.timeSpent} Minutes ({Math.round(user.timeSpent / 60)} Hours) ({Math.round(user.timeSpent / 60 / 24)} Days)</Typography>
        <Typography>UID: {user.userUID}</Typography>
        <Typography>
          Created At: {new Date(user.createdAt._seconds * 1000).toLocaleDateString("en-US") + " "}
                        {new Date(user.createdAt._seconds * 1000).toLocaleTimeString("en-US")}
        </Typography>
        <Typography>Banned: {user.isBanned ? 'Yes' : 'No'}</Typography>
        <Typography>
          Banned Until: {user.banUntil ? new Date(user.banUntil._seconds * 1000).toLocaleString() : 'Not Banned'}
        </Typography>
        <Typography>
          Banned Reason: {user.banReason ? user.banReason : 'No Reason'}
        </Typography>
        <Typography>Times Banned: {user.timesBanned}</Typography>
        <Typography>Permissions: {user.permissions}</Typography>
        <Typography>Referral ID: {user.referralId}</Typography>
        <Typography>Device Info: {JSON.stringify(user.deviceInfo)}</Typography>
        <Typography>Member: {user.member}</Typography>
        <Typography>Money: ${user.money.toFixed(2)}</Typography>
        <Typography>Characters: {user.characters.join(', ')}</Typography>
        <Typography>Costume: {user.costume || 'None'}</Typography>
        <Typography>Face: {user.face}</Typography>
        <Typography>Body: {user.body}</Typography>
        <Typography>Head: {user.head}</Typography>
        <Typography>Inventory: {user.inventory.join(', ') || 'Empty'}</Typography>
        <Typography>Properties: {user.properties.join(', ') || 'None'}</Typography>
        <Typography>Email Verified: {user.emailVerified ? 'Yes' : 'No'}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDetail;