import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Paper, CircularProgress, Grid } from '@mui/material';
import { getAuthToken } from '../utils/firebaseUtils';

const Statistics = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = await getAuthToken();
        const response = await axios.get('/api/api/admin/user-statistics', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching statistics:', error);
        setError('Failed to fetch statistics');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Paper sx={{ padding: 2, marginBottom: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Total Users</Typography>
            <Typography variant="h4">{stats.totalUsers}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Email Verified Users</Typography>
            <Typography variant="h4">{stats.emailVerifiedUsers}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Total LTV</Typography>
            <Typography variant="h4">${stats.totalLTV.toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Current MRR</Typography>
            <Typography variant="h4">${stats.currentMRR.toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Potential Recoverable MRR</Typography>
            <Typography variant="h4">${stats.potentialRecoverableMRR.toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Annual Recurring Revenue (ARR)</Typography>
            <Typography variant="h4">${stats.ARR.toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Total Money</Typography>
            <Typography variant="h4">${stats.totalMoney.toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Total Time Spent (Hours)</Typography>
            <Typography variant="h4">{stats.totalTimeSpent.toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Users Referred</Typography>
            <Typography variant="h4">{stats.usersReferred}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Active Members</Typography>
            <Typography variant="h4">{stats.activeMembers}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Conversion Rate</Typography>
            <Typography variant="h4">{stats.conversionRate.toFixed(2)}%</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Banned Users</Typography>
            <Typography variant="h4">{stats.bannedUsers}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Non-Banned Users</Typography>
            <Typography variant="h4">{stats.nonBannedUsers}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Users Consented to Marketing</Typography>
            <Typography variant="h4">{stats.consentedToMarketing}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Trades Made</Typography>
            <Typography variant="h4">{stats.tradesMade}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Total Payment Fails</Typography>
            <Typography variant="h4">{stats.totalPaymentFails}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Average Risk Score</Typography>
            <Typography variant="h4">{stats.avgRiskScore.toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Average Engagement Score</Typography>
            <Typography variant="h4">{stats.avgEngagementScore.toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Average Achievements</Typography>
            <Typography variant="h4">{stats.avgAchievements.toFixed(2)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box textAlign="center">
            <Typography variant="h6">Open Tickets</Typography>
            <Typography variant="h4">{stats.openTickets}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Statistics;