import React from 'react';
import './AboutUs.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const AboutUs = () => {
    return (
        <div>
            <div className="about-container">
                <h1>About Us</h1>
                <p>Welcome to Waffle Island, your ultimate virtual escape where imagination meets adventure! Our platform is designed to offer a safe, engaging, and fun virtual world experience for users of all ages. At Waffle Island, you can create your unique character, explore diverse environments, play exciting minigames, earn virtual currency, own and customize your home, and interact with other players through trading and social activities.</p>

                <h2>Our Mission</h2>
                <p>At Waffle Island, our mission is to provide a captivating and inclusive virtual world that fosters creativity, social interaction, and endless fun. We aim to continuously evolve our platform with regular updates and new features to keep the adventure fresh and exciting for our community.</p>

                <h2>Our Team</h2>
                <p>Waffle Island is proudly owned and operated by James, who is dedicated to creating a seamless and enjoyable user experience. James works tirelessly to bring you new updates every week, ensuring that there’s always something new to discover on Waffle Island.</p>

                <h2>Community and Safety</h2>
                <p>We prioritize the safety and well-being of our players. Our platform utilizes advanced technologies such as Artificial Intelligence (AI) and Machine Learning (ML) to monitor and enhance user interactions, ensuring a safe and secure environment. We implement real-time monitoring and predictive analytics to detect and mitigate any harmful behavior, ensuring a positive experience for everyone.</p>

                <h2>Join Us</h2>
                <p>Embark on your Waffle Island adventure today! Whether you’re looking to unwind with fun games, make new friends, or showcase your creativity, Waffle Island has something for everyone. Join us and be a part of a vibrant, ever-growing community.</p>

                <p>For more information or support, feel free to contact us at support@waffleisland.co.</p>
            </div>
        </div>
    );
};

export default AboutUs;