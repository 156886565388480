// src/pages/SuccessPage.js
import React from 'react';

const SuccessPage = () => {
  return (
    <div className="success-container">
      <h2>Registration Successful!</h2>
      <p>Welcome to Waffle Island!</p>
    </div>
  );
};

export default SuccessPage;