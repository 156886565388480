import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuthToken } from '../../../utils/firebaseUtils';
import {
  Box,
  Button,
  Container,
  Grid,
  Select,
  MenuItem,
  TextField,
  Typography,
  Snackbar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ManageObjects = () => {
  const [objects, setObjects] = useState([]);
  const [formData, setFormData] = useState({
    action: '',
    actionVar: '',
    animated: false,
    dir: '',
    dirs: '',
    obj: '',
    objType: 'roomObj',
    opacity: '',
    room: '',
    scale: '',
    visible: true,
    x: '',
    y: ''
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentDocId, setCurrentDocId] = useState(null);

  useEffect(() => {
    fetchObjects();
  }, []);

  const fetchObjects = async () => {
    try {
      const token = await getAuthToken();
      const response = await axios.get('/api/api/admin/objects', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setObjects(response.data);
    } catch (error) {
      console.error('Error fetching objects:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const convertedValue =
      ['animated', 'visible'].includes(name) ? (value === 'True') :
      ['dir', 'dirs', 'obj', 'x', 'y'].includes(name) ? (value ? parseInt(value, 10) : '') :
      ['opacity', 'scale'].includes(name) ? (value ? parseFloat(value) : '') :
      value;

    setFormData({ ...formData, [name]: convertedValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAuthToken();
    const headers = { headers: { Authorization: `Bearer ${token}` } };

    if (editMode) {
      try {
        await axios.put(`/api/api/admin/objects/${currentDocId}`, formData, headers);
        setSnackbarMessage('Object updated successfully!');
        setEditMode(false);
        setCurrentDocId(null);
      } catch (error) {
        console.error('Error updating object:', error);
        setSnackbarMessage('Failed to update object');
      }
    } else {
      try {
        await axios.post(`/api/api/admin/objects`, formData, headers);
        setSnackbarMessage('Object added successfully!');
      } catch (error) {
        console.error('Error adding object:', error);
        setSnackbarMessage('Failed to add object');
      }
    }
    setSnackbarOpen(true);
    fetchObjects();
    resetForm();
  };

  const handleEdit = (object) => {
    setFormData({
      action: object.action || '',
      actionVar: object.actionVar || '',
      animated: object.animated !== undefined ? object.animated === 'True' || object.animated === true : false,
      dir: object.dir !== undefined ? object.dir : '',
      dirs: object.dirs !== undefined ? object.dirs : '',
      obj: object.obj !== undefined ? object.obj : '',
      objType: object.objType || 'roomObj',
      opacity: object.opacity !== undefined ? object.opacity : '',
      room: object.room || '',
      scale: object.scale !== undefined ? object.scale : '',
      visible: object.visible !== undefined ? object.visible === 'True' || object.visible === true : true,
      x: object.x !== undefined ? object.x : '',
      y: object.y !== undefined ? object.y : ''
    });
    setEditMode(true);
    setCurrentDocId(object.docId);
  };

  const resetForm = () => {
    setFormData({
      action: '',
      actionVar: '',
      animated: false,
      dir: '',
      dirs: '',
      obj: '',
      objType: 'roomObj',
      opacity: '',
      room: '',
      scale: '',
      visible: true,
      x: '',
      y: ''
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDelete = async (docId) => {
    const token = await getAuthToken();
    const headers = { headers: { Authorization: `Bearer ${token}` } };

    try {
      await axios.delete(`/api/api/admin/objects/${docId}`, headers);
      setSnackbarMessage('Object deleted successfully!');
      fetchObjects();
    } catch (error) {
      console.error('Error deleting object:', error);
      setSnackbarMessage('Failed to delete object');
    }
    setSnackbarOpen(true);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Manage Objects
      </Typography>

      {/* Form for Adding/Updating Objects */}
      <Typography variant="h5" gutterBottom>
        {editMode ? 'Edit Object' : 'Add New Object'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="action"
              label="Action"
              value={formData.action}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="actionVar"
              label="Action Variable"
              value={formData.actionVar}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              name="animated"
              value={formData.animated ? 'True' : 'False'}
              onChange={handleChange}
            >
              <MenuItem value="True">True</MenuItem>
              <MenuItem value="False">False</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="dir"
              label="Direction"
              value={formData.dir}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="dirs"
              label="Dirs"
              value={formData.dirs}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="obj"
              label="Object ID"
              value={formData.obj}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="objType"
              label="Object Type"
              value={formData.objType}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="opacity"
              label="Opacity"
              value={formData.opacity}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="room"
              label="Room ID"
              value={formData.room}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="scale"
              label="Scale"
              value={formData.scale}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              name="visible"
              value={formData.visible ? 'True' : 'False'}
              onChange={handleChange}
            >
              <MenuItem value="True">True</MenuItem>
              <MenuItem value="False">False</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="x"
              label="X Position"
              value={formData.x}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="y"
              label="Y Position"
              value={formData.y}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              {editMode ? 'Update Object' : 'Add Object'}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Display current objects */}
      <Typography variant="h6" gutterBottom>
        Current Objects
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Object ID</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Action Variable</TableCell>
              <TableCell>Animated</TableCell>
              <TableCell>Direction</TableCell>
              <TableCell>Dirs</TableCell>
              <TableCell>Opacity</TableCell>
              <TableCell>Room</TableCell>
              <TableCell>Scale</TableCell>
              <TableCell>Visible</TableCell>
              <TableCell>X Position</TableCell>
              <TableCell>Y Position</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {objects.map((object) => (
              <TableRow key={object.docId}>
                <TableCell>{object.obj}</TableCell>
                <TableCell>{object.action || ''}</TableCell>
                <TableCell>{object.actionVar || ''}</TableCell>
                <TableCell>{object.animated ? 'True' : 'False'}</TableCell>
                <TableCell>{object.dir}</TableCell>
                <TableCell>{object.dirs}</TableCell>
                <TableCell>{object.opacity}</TableCell>
                <TableCell>{object.room}</TableCell>
                <TableCell>{object.scale}</TableCell>
                <TableCell>{object.visible ? 'True' : 'False'}</TableCell>
                <TableCell>{object.x}</TableCell>
                <TableCell>{object.y}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleEdit(object)}>
                    Edit
                  </Button>
                  <Button variant="contained" color="secondary" onClick={() => handleDelete(object.docId)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default ManageObjects;