import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div id="footer">
            &copy; Copyright 2024 Waffle Island&trade; All Rights Reserved.
            <ul>
                <li><a href="https://dev.waffleisland.co/contact.html">Contact Us</a></li>
                <li><a href="https://dev.waffleisland.co/terms-of-service">Terms of Service</a></li>
                <li><a href="https://dev.waffleisland.co/privacy-policy">Privacy Policy</a></li>
                <li><a href="https://dev.waffleisland.co/help">Help</a></li>
                <li className="last"><a href="https://dev.waffleisland.co/about">About Us</a></li>
            </ul>
        </div>
    );
};

export default Footer;