// src/pages/HomePage.js
// src/pages/HomePage.js
import React, { useState } from 'react';
import './HomePage.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const HomePage = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div id="wrapper">
      <div id="main">
        <img style={{ margin: '0 auto', display: 'block' }} src="welcome.png" alt="Welcome"/>
        <div className="blue_bar"></div>
        <div className="btn_container">
          <a className="play" href="https://dev.waffleisland.co/play">Play Now!</a>
          <a className="join" href="https://dev.waffleisland.co/register">Join Waffle Island!</a>
        </div>
      </div>
      <div>
        
      </div>
      <div id="content">
        <div className="section_top_left">
          <h2>What is Waffle Island?</h2>
          <img src="question_waffle.png" alt="What is Waffle Island"/><br/>
          <p>Waffle Island is a fun and exciting new virtual world where users play as a waffle avatar to explore Waffle Island, chat, collect Waffle Cash, play mini-games, make new friends, and plate loads more! The Waffle Island team seeks to provide a safe, user-friendly environment where children and young teens can hang-out and make new friends, while having fun along the way. So what are you waiting for? Come and join this amazingly fun and yummy island of waffles!</p>
        </div>
        <div className="section_top_right">
          <h2>What&rsquo;s New?</h2>
          &mdash;<i>July 1st, 2024</i>&mdash;
          <br/><br/>
          <p>In the heart of a virtual realm, where digital waffles roam free and avatars come alive, an unprecedented mission unfolds. </p>
          <a href="https://waffleisland223.wordpress.com/" style={{ float: 'right' }}>Read more...</a>
        </div>
      </div>
    </div>
  );
};

export default HomePage;



// import React from 'react';
// import './HomePage.css';

// const HomePage = () => {
//   return (
//     <div id="wrapper">
//       <div id="nav">
//         <img src="https://dev.waffleisland.co/assets/graphics/logo.svg" alt="Waffle Island Logo"/>
//         <ul>
//           <li><a href="https://dev.waffleisland.co/store.html">Store</a></li>
//           <li><a href="https://dev.waffleisland.co/help.html">Help</a></li>
//           <li><a href="https://dev.waffleisland.co/about.html">About</a></li>
//           <li><a href="https://waffleisland223.wordpress.com/">Blog</a></li>
//           <li><a href="https://dev.waffleisland.co/checkout/">VIP</a></li>
//           <li><a href="https://dev.waffleisland.co/">Home</a></li>
//         </ul>
//       </div>
//       <div id="main">
//         <img style={{ margin: '0 auto', display: 'block' }} src="welcome.png" alt="Welcome"/>
//         <div className="blue_bar"></div>
//         <div className="btn_container">
//           <a className="play" href="https://dev.waffleisland.co/play">Play Now!</a>
//           <a className="join" href="https://dev.waffleisland.co/register">Join Waffle Island!</a>
//         </div>
//       </div>
//       <div id="content">
//         <div className="section_top_left">
//           <h2>What is Waffle Island?</h2>
//           <img src="question_waffle.png" alt="What is Waffle Island"/><br/>
//           <p>Waffle Island is a fun and exciting new virtual world where users play as a waffle avatar to explore Waffle Island, chat, collect Waffle Cash, play mini-games, make new friends, and plate loads more! The Waffle Island team seeks to provide a safe, user-friendly environment where children and young teens can hang-out and make new friends, while having fun along the way. So what are you waiting for? Come and join this amazingly fun and yummy island of waffles!</p>
//         </div>
//         <div className="section_top_right">
//           <h2>What&rsquo;s New?</h2>
//           &mdash;<i>June 30th, 2024</i>&mdash;
//           <br/><br/>
//           <p>Top Secret Development</p>
//           <a href="https://waffleisland223.wordpress.com/" style={{ float: 'right' }}>Read more...</a>
//         </div>
//       </div>
//       <div id="footer">
//         &copy; Copyright 2024 Waffle Island&trade; All Rights Reserved.
//         <ul>
//           <li><a href="https://dev.waffleisland.co/contact.html">Contact Us</a></li>
//           <li><a href="https://dev.waffleisland.co/terms.html">Terms of Use</a></li>
//           <li><a href="https://dev.waffleisland.co/help.html">Help</a></li>
//           <li className="last"><a href="https://dev.waffleisland.co/about.html">About Us</a></li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default HomePage;