import React from 'react';
import './TermsOfService.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

const TermsOfService = () => {
    return (
        <div>
            <div className="terms-container">
            
                <h1>Terms of Service</h1>
                <p>Welcome to Waffle Island! These terms and conditions outline the rules and regulations for the use of Waffle Island's website and virtual world.</p>

                <h2>1. Introduction</h2>
                <p>By accessing this website and using our services, you agree to comply with these terms and conditions. If you do not agree with any part of these terms, please do not use our website or services.</p>

                <h2>2. Intellectual Property Rights</h2>
                <p>Unless otherwise stated, Waffle Island and/or its licensors own the intellectual property rights for all material on Waffle Island. All intellectual property rights are reserved. You may access this from Waffle Island for your own personal use subjected to restrictions set in these terms and conditions.</p>
                <ul>
                    <li>You must not republish material from Waffle Island</li>
                    <li>You must not sell, rent, or sub-license material from Waffle Island</li>
                    <li>You must not reproduce, duplicate, or copy material from Waffle Island</li>
                    <li>You must not redistribute content from Waffle Island</li>
                </ul>

                <h2>3. User Accounts</h2>
                <p>When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.</p>

                <h2>4. User Content</h2>
                <p>Users may post and exchange opinions, information, and other content in certain areas of the website. Waffle Island does not filter, edit, publish, or review content prior to its presence on the website. Content does not reflect the views and opinions of Waffle Island, its agents, or affiliates.</p>

                <h2>5. Prohibited Activities</h2>
                <p>As a user of the Site, you agree not to:</p>
                <ul>
                    <li>Systematically retrieve data or other content from the Site to create a collection or database without written permission from us.</li>
                    <li>Use the Site to advertise or offer to sell goods and services.</li>
                    <li>Engage in unauthorized framing of or linking to the Site.</li>
                    <li>Trick, defraud, or mislead us and other users.</li>
                    <li>Use the Site in a manner inconsistent with any applicable laws or regulations.</li>
                </ul>

                <h2>6. Limitation of Liability</h2>
                <p>In no event shall Waffle Island, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein.</p>

                <h2>7. Changes to These Terms</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

                <h2>8. Contact Us</h2>
                <p>If you have any questions about these Terms, please contact us at support@waffleisland.co.</p>
            </div>
        </div>
    );
};

export default TermsOfService;