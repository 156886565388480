import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import axios from 'axios';
import { getAuthToken } from '../utils/firebaseUtils';
import SupportTickets from './components/admin/SupportTickets';
import Statistics from './Statistics';
import FlaggedChats from './FlaggedChats';
import AddFurnitureItem from './components/admin/AddFurnitureItem';
import ManageItems from './components/admin/ManageItems';
import ManageObjects from './components/admin/ManageObjects';
import UserDetail from './components/admin/UserDetail';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  IconButton,
  InputBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import GavelIcon from '@mui/icons-material/Gavel';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import './AdminPanel.css';

const formatDate = require('../utils/formatDate');

const AdminPanel = () => {
  // States for users, support tickets, and UI
  const [username, setUsername] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [banDuration, setBanDuration] = useState('');
  const [banReason, setBanReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ticketError, setTicketError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [selectedSection, setSelectedSection] = useState(''); // For the dropdown menu
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [supportTickets, setSupportTickets] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [liveChatMessages, setLiveChatMessages] = useState([]);

  useEffect(() => {
    fetchUsers();
    fetchSupportTickets();
    connectToSocket();
  }, [page, pageSize]);

  // WebSocket connection for live chat
  const connectToSocket = async () => {
    const token = await getAuthToken();
    const socket = socketIOClient('https://dev.waffleisland.co', {
      query: { token },
    });
    socket.on('connect', () => console.log("Connected"));
    socket.on('admin_message', (data) => {
      setLiveChatMessages((prevMessages) => [...prevMessages, data]);
    });
    socket.on('disconnect', (data) => {
      console.log("Disconnected:", data);
    });
    return () => socket.disconnect();
  };

  // Fetch users from API
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      const response = await axios.get('/api/api/admin/users', {
        headers: { Authorization: `Bearer ${token}` },
        params: { page, pageSize },
      });
      setUsers(response.data.users);
      setTotalUsers(response.data.total);
    } catch (error) {
      console.error('Failed to fetch users:', error);
      setError('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  // Search users by username
  const searchUsers = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      const response = await axios.get('/api/api/admin/search-users', {
        headers: { Authorization: `Bearer ${token}` },
        params: { username },
      });
      setUsers(response.data.users);
    } catch (error) {
      console.error('Failed to search users:', error);
      setError('Failed to search users');
    } finally {
      setLoading(false);
    }
  };

  // Fetch support tickets from API
  const fetchSupportTickets = async () => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      const response = await axios.get('/api/api/admin/support-tickets', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSupportTickets(response.data);
    } catch (error) {
      console.error('Failed to fetch support tickets:', error);
      setTicketError('Failed to fetch support tickets');
    } finally {
      setLoading(false);
    }
  };

  // Close support ticket
  const closeSupportTicket = async (ticketId) => {
    setLoading(true);
    try {
      const token = await getAuthToken();
      await axios.post(`/api/api/admin/support-tickets/${ticketId}/close`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSnackbarOpen(true);
      setSnackBarMessage('Successfully Closed Ticket');
      fetchSupportTickets();
    } catch (error) {
      console.error('Failed to close ticket:', error);
      setTicketError('Failed to close ticket');
    } finally {
      setLoading(false);
    }
  };

  // Handle dropdown section selection
  const handleSectionChange = (e) => {
    setSelectedSection(e.target.value);
  };

  // Snackbar close handler
  const handleSnackbarClose = () => setSnackbarOpen(false);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        <br />
        <img src="logo.svg" alt="Logo" /><br />
        Admin Panel
      </Typography>

      {/* Dropdown menu for section selection */}
      <Box mb={4} mt={2}>
        <Select
          fullWidth
          value={selectedSection}
          onChange={handleSectionChange}
          displayEmpty
        >
          <MenuItem value="" disabled>Select a section</MenuItem>
          <MenuItem value="statistics">Statistics</MenuItem>
          <MenuItem value="addItem">Add New Furniture Item</MenuItem>
          <MenuItem value="manageItems">Manage Items</MenuItem>
          <MenuItem value="manageObjects">Manage Objects</MenuItem>
        </Select>
      </Box>

      {/* Conditionally render the selected section */}
      {selectedSection === 'statistics' && <Statistics />}
      {selectedSection === 'addItem' && <AddFurnitureItem />}
      {selectedSection === 'manageItems' && <ManageItems />}
      {selectedSection === 'manageObjects' && <ManageObjects />}

      {/* Search users */}
      <Paper component="form" className="search-section" onSubmit={(e) => e.preventDefault()}>
        <InputBase
          placeholder="Search by username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="search-input"
        />
        <IconButton type="button" onClick={searchUsers} className="search-button">
          <SearchIcon />
        </IconButton>
      </Paper>

      {/* User List */}
      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      <Typography>Total Users: {totalUsers}</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 ? (
              users.map((user) => (
                <TableRow key={user.userUID}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell align="right">
                    <IconButton>
                      <GavelIcon />
                    </IconButton>
                    <IconButton>
                      <AccessibilityIcon />
                    </IconButton>
                    <IconButton>
                      <ChatIcon />
                    </IconButton>
                    <IconButton>
                      <PersonIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No users found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
        <Button
          variant="contained"
          disabled={page === 1}
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </Button>
        <Typography>{page}</Typography>
        <Button
          variant="contained"
          disabled={users.length < pageSize}
          onClick={() => setPage((prev) => prev + 1)}
        >
          Next
        </Button>
        <TextField
          select
          label="Page Size"
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
          SelectProps={{
            native: true,
          }}
        >
          {[10, 25, 50].map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </TextField>
      </Box>

      {/* Live Chat Logs */}
      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          Live Chat Logs
        </Typography>
        <Paper className="live-chat-logs">
          <TableBody>
            {liveChatMessages
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((msg, index) => (
                <TableRow key={msg.uid}>
                  <TableCell>
                    <div className={`chat-log-message ${msg.flagged ? 'red-text' : ''}`}>
                      <strong>{msg.username} [{msg.room}]</strong>: {msg.message}
                      <span className="timestamp">{formatDate(msg.createdAt)}</span>
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton>
                      <GavelIcon />
                    </IconButton>
                    <IconButton>
                      <ChatIcon />
                    </IconButton>
                    <IconButton>
                      <PersonIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Paper>
      </Box>

      {/* Support Tickets */}
      <SupportTickets
        supportTickets={supportTickets}
        loading={loading}
        error={ticketError}
        closeSupportTicket={closeSupportTicket}
      />

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackBarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default AdminPanel;



















// import React, { useState, useEffect } from 'react';
// import socketIOClient from 'socket.io-client';
// import axios from 'axios';
// import { getAuthToken } from '../utils/firebaseUtils';
// import SupportTickets from './components/admin/SupportTickets';
// import Statistics from './Statistics';
// import FlaggedChats from './FlaggedChats';
// import AddFurnitureItem from './AddFurnitureItem'; // Import the new component for adding furniture items
// import ManageItems from './components/admin/ManageItems'; // New component import
// import ManageObjects from './components/admin/ManageObjects';  // Adjust the path based on your folder structure
// import {
//   Box,
//   Button,
//   CircularProgress,
//   Container,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Grid,
//   IconButton,
//   InputBase,
//   MenuItem,
//   Paper,
//   Select,
//   Snackbar,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Typography,
// } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import GavelIcon from '@mui/icons-material/Gavel';
// import ChatIcon from '@mui/icons-material/Chat';
// import PersonIcon from '@mui/icons-material/Person';
// import CloseIcon from '@mui/icons-material/Close';
// import AccessibilityIcon from '@mui/icons-material/Accessibility';
// import UserDetail from './components/admin/UserDetail';
// import './AdminPanel.css';
// const formatDate = require('../utils/formatDate');

// const AdminPanel = () => {
//   const [username, setUsername] = useState('');
//   const [users, setUsers] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [banDuration, setBanDuration] = useState('');
//   const [banReason, setBanReason] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [ticketError, setTicketError] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [unbanDialogOpen, setUnbanDialogOpen] = useState(false);
//   const [page, setPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [supportPage, setSupportPage] = useState(1);
//   const [supportPageSize, setSupportPageSize] = useState(10);
//   const [totalUsers, setTotalUsers] = useState(0);
//   const [chatLogs, setChatLogs] = useState([]);
//   const [chatLogsDialogOpen, setChatLogsDialogOpen] = useState(false);
//   const [userDetailOpen, setUserDetailOpen] = useState(false);
//   const [liveChatMessages, setLiveChatMessages] = useState([]);
//   const [supportTickets, setSupportTickets] = useState([]);
//   const [snackBarMessage, setSnackBarMessage] = useState('');
//   const [testMsg, setTestMsg] = useState('Test');
//   const [showAddItemForm, setShowAddItemForm] = useState(false); // New state to toggle AddFurnitureItem form
//   const [showManageItems, setShowManageItems] = useState(false); // State to manage visibility of ManageItems
//   const [showManageObjects, setShowManageObjects] = useState(false);

//   const handleShowManageObjects = () => {
//     setShowManageObjects(true);
//     setShowManageItems(false);  // Hide other sections if needed
//   };

//   const handleShowManageItems = () => {
//     setShowManageItems(true); // Show ManageItems
//   };

//   const handleHideManageItems = () => {
//     setShowManageItems(false); // Hide ManageItems and return to default view
//   };


//   useEffect(() => {
//     fetchUsers();
//     fetchSupportTickets();
//   }, [supportPage, supportPageSize]);

//   useEffect(() => {
//     fetchUsers();
//   }, [page, pageSize]);

//   useEffect(() => {
//     const connectToSocket = async () => {
//       const token = await getAuthToken();
//       const socket = socketIOClient('https://dev.waffleisland.co', {
//         query: { token },
//       });
//       socket.on('connect', () => {
//         console.log("Connected");
//       });
//       socket.emit('admin_connect');
//       socket.on('error', (err) => {
//         console.log('Error:', err);
//       });
//       socket.on('disconnect', (data) => {
//         setTestMsg("Disconnected:", data);
//       });
//       socket.on('admin_message', (data) => {
//         console.log("Data:", data);
//         setLiveChatMessages((prevMessages) => [...prevMessages, data]);
//       });

//       return () => {
//         socket.disconnect();
//       };
//     };

//     connectToSocket();
//   }, []);

//   const fetchUsers = async () => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get('/api/api/admin/users', {
//         headers: { Authorization: `Bearer ${token}` },
//         params: { page, pageSize },
//       });
//       setUsers(response.data.users);
//       setTotalUsers(response.data.total);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch users');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const searchUsers = async () => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get('/api/api/admin/search-users', {
//         headers: { Authorization: `Bearer ${token}` },
//         params: { username },
//       });
//       setUsers(response.data.users);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch users');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const viewBanUserInfo = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get(`/api/api/admin/users/${userId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSelectedUser(response.data);
//       setDialogOpen(true);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch user info');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const viewUnbanUserInfo = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get(`/api/api/admin/users/${userId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSelectedUser(response.data);
//       setUnbanDialogOpen(true);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch user info');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const viewUserDetail = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get(`/api/api/admin/users/${userId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSelectedUser(response.data);
//       setUserDetailOpen(true);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch user info');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const viewChatLogs = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get(`/api/api/admin/chats/${userId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setChatLogs(response.data);
//       setChatLogsDialogOpen(true);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch chat logs');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const banUser = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       await axios.post(
//         '/api/api/admin/ban',
//         { userId, duration: banDuration, banReason: banReason },
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       setSnackbarOpen(true);
//       setDialogOpen(false);
//       setSnackBarMessage('User Banned Successfully');
//     } catch (error) {
//       console.error(error);
//       setError('Failed to ban user');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const unbanUser = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       await axios.post(
//         '/api/api/admin/unban',
//         { userId },
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       setSnackbarOpen(true);
//       setDialogOpen(false);
//       setSnackBarMessage('User Unbanned Successfully');
//     } catch (error) {
//       console.error(error);
//       setError('Failed to unban user');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchSupportTickets = async () => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get('/api/api/admin/support-tickets', {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSupportTickets(response.data);
//     } catch (error) {
//       console.error(error);
//       setTicketError('Failed to fetch support tickets');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const closeSupportTicket = async (ticketId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       await axios.post(`/api/api/admin/support-tickets/${ticketId}/close`, {}, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSnackbarOpen(true);
//       fetchSupportTickets();
//       setSnackBarMessage('Successfully Closed Ticket');
//     } catch (error) {
      
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     const handleSnackbarClose = () => {
//       setSnackbarOpen(false);
//     };
  
//     const handlePageChange = (newPage) => {
//       setPage(newPage);
//     };
  
//     const membershipStatus = (membership) => {
//       switch (membership) {
//         case 0:
//           return "Non-paying";
//         case 1:
//           return "Basic $5/month";
//         case 2:
//           return "Standard $10/month";
//         case 3:
//           return "Premium $20/month";
//         case 4:
//           return "VIP $50/month";
//         case 5:
//           return "DELUXE VIP $100/month";
//         default:
//           return "Unknown";
//       }
//     };
  
//     return (
//       <Container maxWidth="lg">
//         <Typography variant="h4" gutterBottom>
//           <br />
//           <img src="logo.svg" alt="Logo" /><br />
//           Admin Panel
//         </Typography>
//         <Container maxWidth="lg">
//           <Statistics /> 
//         </Container>
//          {/* Button to toggle ManageItems view */}
//         <Button variant="contained" color="primary" onClick={handleShowManageItems}>
//           Manage Items
//         </Button>
//         <Button variant="contained" color="primary" onClick={handleShowManageObjects}>
//           Manage Objects
//        </Button>

//         {/* Conditional Rendering */}
//         {showManageObjects && <ManageObjects />}
//         {showManageItems ? (
//           <ManageItems onClose={handleHideManageItems} /> // Render ManageItems with a close handler
//         ) : (
//           <div>
//             {/* Your existing admin panel content */}
//           </div>
//         )}
//         {/* Button to toggle the AddFurnitureItem form */}
//         <Box display="flex" justifyContent="flex-end" mb={2}>
//           <Button variant="contained" color="primary" onClick={() => setShowAddItemForm(!showAddItemForm)}>
//             {showAddItemForm ? 'Hide Add Item Form' : 'Add New Furniture Item'}
//           </Button>
//         </Box>
  
//         {showAddItemForm ? (
//           <AddFurnitureItem />  // Conditionally render the AddFurnitureItem component
//         ) : (
//           <>
//             <Paper component="form" className="search-section" onSubmit={(e) => e.preventDefault()}>
//               <InputBase
//                 placeholder="Search by username"
//                 value={username}
//                 onChange={(e) => setUsername(e.target.value)}
//                 className="search-input"
//               />
//               <IconButton type="button" onClick={searchUsers} className="search-button">
//                 <SearchIcon /> 
//               </IconButton>
//             </Paper>
//             {loading && <CircularProgress className="loading-spinner" />}
//             {error && <Typography color="error">{error}</Typography>}
//             <Typography>Total Users: {totalUsers}</Typography>
//             <TableContainer component={Paper}>
//               <Table>
//                 <TableHead>
//                   <TableRow>
//                     <TableCell>Username</TableCell>
//                     <TableCell>Email</TableCell>
//                     <TableCell align="right">Actions</TableCell>
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {users.length > 0 ? (
//                     users.map((user) => (
//                       <TableRow key={user.userUID}>
//                         <TableCell component="th" scope="row">
//                           {user.username}
//                         </TableCell>
//                         <TableCell>{user.email}</TableCell>
//                         <TableCell align="right">
//                           <IconButton onClick={() => viewBanUserInfo(user.userUID)}>
//                             <GavelIcon />
//                           </IconButton>
//                           <IconButton onClick={() => viewUnbanUserInfo(user.userUID)}>
//                             <AccessibilityIcon />
//                           </IconButton>
//                           <IconButton onClick={() => viewChatLogs(user.userUID)}>
//                             <ChatIcon />
//                           </IconButton>
//                           <IconButton onClick={() => viewUserDetail(user.userUID)}>
//                             <PersonIcon />
//                           </IconButton>
//                         </TableCell>
//                       </TableRow>
//                     ))
//                   ) : (
//                     <TableRow>
//                       <TableCell colSpan={3} align="center">
//                         No users found
//                       </TableCell>
//                     </TableRow>
//                   )}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
//               <Button
//                 variant="contained"
//                 disabled={page === 1}
//                 onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
//               >
//                 Previous
//               </Button>
//               <Typography>{page}</Typography>
//               <Button
//                 variant="contained"
//                 disabled={users.length < pageSize}
//                 onClick={() => setPage((prev) => prev + 1)}
//               >
//                 Next
//               </Button>
//               <TextField
//                 select
//                 label="Page Size"
//                 value={pageSize}
//                 onChange={(e) => setPageSize(Number(e.target.value))}
//                 SelectProps={{
//                   native: true,
//                 }}
//               >
//                 {[10, 25, 50].map((size) => (
//                   <option key={size} value={size}>
//                     {size}
//                   </option>
//                 ))}
//               </TextField>
//             </Box>
//           </>
//         )}
  
//         {/* Dialogs, User Details, and other components remain unchanged */}
//         <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
//           <DialogTitle>Ban User</DialogTitle>
//           <DialogContent>
//             {selectedUser && (
//               <>
//                 <Typography>Username: {selectedUser.username}</Typography>
//                 <Typography>Email: {selectedUser.email}</Typography>
//                 <Typography>Time Spent: {selectedUser.timeSpent} Minutes</Typography>
//                 <Typography>Member Status: {membershipStatus(selectedUser.member)}</Typography>
//                 <TextField
//                   label="Ban duration (minutes)"
//                   value={banDuration}
//                   onChange={(e) => setBanDuration(e.target.value)}
//                   fullWidth
//                   margin="normal"
//                 />
//                 <TextField
//                   label="Ban reason"
//                   value={banReason}
//                   onChange={(e) => setBanReason(e.target.value)}
//                   fullWidth
//                   margin="normal"
//                 />
//               </>
//             )}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => banUser(selectedUser.userUID)} color="primary">
//               Ban User
//             </Button>
//             <Button onClick={() => setDialogOpen(false)} color="secondary">
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//         <Dialog open={unbanDialogOpen} onClose={() => setUnbanDialogOpen(false)} fullWidth>
//           <DialogTitle>Unban User</DialogTitle>
//           <DialogContent>
//             {selectedUser && (
//               <>
//                 <Typography>
//                   <b>{selectedUser.isBanned ? 'User is currently banned.' : 'User is not banned.'}</b><br />
//                 </Typography>
//                 <Typography>Username: {selectedUser.username}</Typography>
//                 <Typography>Email: {selectedUser.email}</Typography>
//                 <Typography>Time Spent: {selectedUser.timeSpent} Minutes</Typography>
//                 <Typography>
//                   Banned Until: 
//                   {selectedUser.banUntil ? 
//                     new Date(selectedUser.banUntil._seconds * 1000).toLocaleDateString("en-US") + " " +
//                     new Date(selectedUser.banUntil._seconds * 1000).toLocaleTimeString("en-US") :
//                     ' N/A'}
//                 </Typography>
//                 <Typography>Ban Reason: {selectedUser.banReason}</Typography>
//                 <Typography>Times Banned: {selectedUser.timesBanned}</Typography>
//                 <Typography>Risk Score: {selectedUser.riskScore}</Typography>
//                 <Typography>Member Status: {membershipStatus(selectedUser.member)}</Typography>
//               </>
//             )}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => unbanUser(selectedUser.userUID)} color="primary">
//               Unban User
//             </Button>
//             <Button onClick={() => setUnbanDialogOpen(false)} color="secondary">
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//         <Dialog open={chatLogsDialogOpen} onClose={() => setChatLogsDialogOpen(false)} fullWidth>
//           <DialogTitle>Chat Logs</DialogTitle>
//           <DialogContent>
//             {chatLogs.length > 0 ? (
//               chatLogs.map((log, index) => (
//                 <Box key={index} className="chat-log">
//                   <Typography variant="body2">
//                     <strong>{log.username}:</strong> {log.msg}
//                   </Typography>
//                   <Typography variant="caption" color="textSecondary">
//                     {"Room: " + log.room} 
//                   </Typography><br />
//                   <Typography variant="caption" color="textSecondary">
//                     {new Date(log.createdAt._seconds * 1000).toLocaleDateString("en-US") + " "}
//                     {new Date(log.createdAt._seconds * 1000).toLocaleTimeString("en-US")}
//                   </Typography>
//                 </Box>
//               ))
//             ) : (
//               <Typography>No chat logs found</Typography>
//             )}
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={() => setChatLogsDialogOpen(false)} color="secondary">
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//         <UserDetail
//           user={selectedUser}
//           open={userDetailOpen}
//           onClose={() => setUserDetailOpen(false)}
//         />
//         <Snackbar
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={handleSnackbarClose}
//           message={snackBarMessage}
//           action={
//             <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
//               <CloseIcon fontSize="small" />
//             </IconButton>
//           }
//         />
//         <FlaggedChats viewBanUserInfo={viewBanUserInfo} viewChatLogs={viewChatLogs} viewUserDetail={viewUserDetail} />
//         <Box mt={4}>
//           <Typography variant="h5" gutterBottom>
//             Live Chat Logs
//           </Typography>
//           <Paper className="live-chat-logs">
//           <div className="chat-log-messages">
//             <TableBody>
//               {liveChatMessages
//                 .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
//                 .map((msg, index) => (
//                   <TableRow key={msg.uid} className="chat-log-row">
//                     <TableCell>
//                       <div key={index} className={`chat-log-message ${msg.flagged ? 'red-text' : ''}`}>
//                         <strong>{msg.username} [{msg.room}]</strong>: {msg.message} <span className="timestamp">{formatDate(msg.createdAt)}</span> [Banned: {msg.ban ? 'Yes' : 'No'}]
//                       </div>
//                     </TableCell>
//                     <TableCell align="right" className="icon-buttons">
//                       <IconButton onClick={() => viewBanUserInfo(msg.uid)}>
//                         <GavelIcon />
//                       </IconButton>
//                       <IconButton onClick={() => viewChatLogs(msg.uid)}>
//                         <ChatIcon />
//                       </IconButton>
//                       <IconButton onClick={() => viewUserDetail(msg.uid)}>
//                         <PersonIcon />
//                       </IconButton>
//                     </TableCell>
//                   </TableRow>
//                 ))}
//             </TableBody>
//           </div>
//         </Paper>
//       </Box>
//       <SupportTickets
//         supportTickets={supportTickets}
//         loading={loading}
//         error={ticketError}
//         closeSupportTicket={closeSupportTicket}
//         formatDate={formatDate}
//       />
//     </Container>
//   );
// };

// export default AdminPanel;























// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom'; // New imports for routing
// import AddFurnitureItem from './AddFurnitureItem'; // New import for the AddFurnitureItem component
// import socketIOClient from 'socket.io-client';
// import axios from 'axios';
// import { getAuthToken } from '../utils/firebaseUtils';
// import SupportTickets from './SupportTickets'; // Add this import for the new SupportTickets component
// import Statistics from './Statistics';
// import FlaggedChats from './FlaggedChats';
// import {
//   Box,
//   Button,
//   CircularProgress,
//   Container,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Grid,
//   IconButton,
//   InputBase,
//   MenuItem,
//   Paper,
//   Select,
//   Snackbar,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   TextField,
//   Typography,
// } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import GavelIcon from '@mui/icons-material/Gavel';
// import ChatIcon from '@mui/icons-material/Chat';
// import PersonIcon from '@mui/icons-material/Person';
// import CloseIcon from '@mui/icons-material/Close';
// import AccessibilityIcon from '@mui/icons-material/Accessibility';
// import UserDetail from './UserDetail';
// import './AdminPanel.css';
// const formatDate = require('../utils/formatDate');


// const AdminPanel = () => {
//   const [username, setUsername] = useState('');
//   const [users, setUsers] = useState([]);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [banDuration, setBanDuration] = useState('');
//   const [banReason, setBanReason] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [ticketError, setTicketError] = useState(null);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [dialogOpen, setDialogOpen] = useState(false);
//   const [unbanDialogOpen, setUnbanDialogOpen] = useState(false);
//   const [page, setPage] = useState(1);
//   const [pageSize, setPageSize] = useState(10);
//   const [supportPage, setSupportPage] = useState(1);
//   const [supportPageSize, setSupportPageSize] = useState(10);
//   const [totalUsers, setTotalUsers] = useState(0);
//   const [chatLogs, setChatLogs] = useState([]);
//   const [chatLogsDialogOpen, setChatLogsDialogOpen] = useState(false);
//   const [userDetailOpen, setUserDetailOpen] = useState(false);
//   const [liveChatMessages, setLiveChatMessages] = useState([]);
//   const [supportTickets, setSupportTickets] = useState([]);
//   const [snackBarMessage, setSnackBarMessage] = useState('');
//   const [testMsg, setTestMsg] = useState('Test');
//   useEffect(() => {
//     fetchUsers();
//     fetchSupportTickets(); // Fetch support tickets when the component mounts
//   }, [supportPage, supportPageSize]);
//   useEffect(() => {
//     fetchUsers();
//   }, [page, pageSize]);

//   useEffect(() => {
//     const connectToSocket = async () => {
//       const token = await getAuthToken();
//       const socket = socketIOClient('https://dev.waffleisland.co', {
//         query: { token },
//       });
//       socket.on('connect', (data) => {
//         console.log("Connected");
//         // Send a large number of request
//       })
//       socket.emit('admin_connect');
//       socket.on('error', (err) => {
//         console.log('Error:', err);
//       });
//       socket.on('disconnect', (data) => {
//         setTestMsg("Disconnected:", data);
//       });
//       socket.on('admin_message', (data) => {
//         console.log("Data:", data);
        
//         setLiveChatMessages((prevMessages) => [...prevMessages, data]);
//       });

//       return () => {
//         socket.disconnect();
//       };
//     };

//     connectToSocket();
//   }, []);

//   const fetchUsers = async () => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get('/api/api/admin/users', {
//         headers: { Authorization: `Bearer ${token}` },
//         params: { page, pageSize },
//       });
//       setUsers(response.data.users);
//       setTotalUsers(response.data.total);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch users');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const searchUsers = async () => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get('/api/api/admin/search-users', {
//         headers: { Authorization: `Bearer ${token}` },
//         params: { username },
//       });
//       setUsers(response.data.users);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch users');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const viewBanUserInfo = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get(`/api/api/admin/users/${userId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSelectedUser(response.data);
//       setDialogOpen(true);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch user info');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const viewUnbanUserInfo = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get(`/api/api/admin/users/${userId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSelectedUser(response.data);
//       setUnbanDialogOpen(true);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch user info');
//     } finally {
//       setLoading(false);
//     }
//   };

//   //July 3rd, 2024
//   const viewUserDetail = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get(`/api/api/admin/users/${userId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSelectedUser(response.data);
//       setUserDetailOpen(true);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch user info');
//     } finally {
//       setLoading(false);
//     }
//   };
  

//   const viewChatLogs = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get(`/api/api/admin/chats/${userId}`, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setChatLogs(response.data);
//       setChatLogsDialogOpen(true);
//     } catch (error) {
//       console.error(error);
//       setError('Failed to fetch chat logs');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const banUser = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       await axios.post(
//         '/api/api/admin/ban',
//         { userId, duration: banDuration, banReason: banReason, },
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       setSnackbarOpen(true);
//       setDialogOpen(false);
//       setSnackBarMessage('User Banned Successfully');
//     } catch (error) {
//       console.error(error);
//       setError('Failed to ban user');
//     } finally {
//       setLoading(false);
//     }
//   };
//   //July 16th, 2024 2:37pm
//   const unbanUser = async (userId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       await axios.post(
//         '/api/api/admin/unban',
//         { userId },
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );
//       setSnackbarOpen(true);
//       setDialogOpen(false);
//       setSnackBarMessage('User Unbanned Successfully');
//     } catch (error) {
//       console.error(error);
//       setError('Failed to unban user');
//     } finally {
//       setLoading(false);
//     }
//   };
//   //July 6th, 2024 11:32pm
//   const fetchSupportTickets = async () => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       const response = await axios.get('/api/api/admin/support-tickets', {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSupportTickets(response.data);
//     } catch (error) {
//       console.error(error);
//       setTicketError('Failed to fetch support tickets');
//     } finally {
//       setLoading(false);
//     }
//   };
//   //July 6th, 2024 11:32pm
//   const closeSupportTicket = async (ticketId) => {
//     setLoading(true);
//     try {
//       const token = await getAuthToken();
//       await axios.post(`/api/api/admin/support-tickets/${ticketId}/close`, {}, {
//         headers: { Authorization: `Bearer ${token}` },
//       });
//       setSnackbarOpen(true);
//       fetchSupportTickets(); // Refresh the support tickets after closing one
//       setSnackBarMessage('Successfuly Closed Ticket');
//     } catch (error) {
//       console.error(error);
//       setError('Failed to close support ticket');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   const handlePageChange = (newPage) => {
//     setPage(newPage);
//   }

//   const membershipStatus = (membership) => {
//     if(membership == 0) {
//         return "Non-paying";
//     }
//     if(membership == 1) {
//         return "Basic $5/month";
//     }
//     if(membership == 2) {
//         return "Standard $10/month";
//     }
//     if(membership == 3) {
//         return "Premium $20/month";
//     }
//     if(membership == 4) {
//         return "VIP $50/month";
//     }
//     if(membership == 5) {
//         return "DELUXE VIP $100/month";
//     }
//   };

//   return (
//     <Container maxWidth="lg">
//       <Typography variant="h4" gutterBottom>
//         <br></br>
//         <img src="logo.svg" /><br></br>
//         Admin Panel
//       </Typography>
//       <Container maxWidth="lg">
//       <Statistics /> 
//     </Container>
//       <Paper component="form" className="search-section" onSubmit={(e) => e.preventDefault()}>
//         <InputBase
//           placeholder="Search by username"
//           value={username}
//           onChange={(e) => setUsername(e.target.value)}
//           className="search-input"
//         />
//         <IconButton type="button" onClick={searchUsers} className="search-button">
//           <SearchIcon />
//         </IconButton>
//       </Paper>
//       {loading && <CircularProgress className="loading-spinner" />}
//       {error && <Typography color="error">{error}</Typography>}
//       <Typography>Total Users: {totalUsers}</Typography>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell>Username</TableCell>
//               <TableCell>Email</TableCell>
//               <TableCell align="right">Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {users.length > 0 ? (
//               users.map((user) => (
//                 <TableRow key={user.userUID}>
//                   <TableCell component="th" scope="row">
//                     {user.username}
//                   </TableCell>
//                   <TableCell>{user.email}</TableCell>
//                   <TableCell align="right">
//                     <IconButton onClick={() => viewBanUserInfo(user.userUID)}>
//                       <GavelIcon />
//                     </IconButton>
//                     <IconButton onClick={() => viewUnbanUserInfo(user.userUID)}>
//                       <AccessibilityIcon />
//                     </IconButton>
//                     <IconButton onClick={() => viewChatLogs(user.userUID)}>
//                       <ChatIcon />
//                     </IconButton>
//                     <IconButton onClick={() => viewUserDetail(user.userUID)}>
//                       <PersonIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))
//             ) : (
//               <TableRow>
//                 <TableCell colSpan={3} align="center">
//                   No users found
//                 </TableCell>
//               </TableRow>
//             )}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
//         <Button
//           variant="contained"
//           disabled={page === 1}
//           onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
//         >
//           Previous
//         </Button>
//         <Typography>{page}</Typography>
//         <Button
//           variant="contained"
//           disabled={users.length < pageSize}
//           onClick={() => setPage((prev) => prev + 1)}
//         >
//           Next
//         </Button>
//         <TextField
//           select
//           label="Page Size"
//           value={pageSize}
//           onChange={(e) => setPageSize(Number(e.target.value))}
//           SelectProps={{
//             native: true,
//           }}
//         >
//           {[10, 25, 50].map((size) => (
//             <option key={size} value={size}>
//               {size}
//             </option>
//           ))}
//         </TextField>
//       </Box>
//       <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
//         <DialogTitle>Ban User</DialogTitle>
//         <DialogContent>
//           {selectedUser && (
//             <>
//               <Typography>Username: {selectedUser.username}</Typography>
//               <Typography>Email: {selectedUser.email}</Typography>
//               <Typography>Time Spent: {selectedUser.timeSpent} Minutes</Typography>
//               <Typography>Member Status: {membershipStatus(selectedUser.member)}</Typography>
//               <TextField
//                 label="Ban duration (minutes)"
//                 value={banDuration}
//                 onChange={(e) => setBanDuration(e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//               <TextField
//                 label="Ban reason"
//                 value={banReason}
//                 onChange={(e) => setBanReason(e.target.value)}
//                 fullWidth
//                 margin="normal"
//               />
//             </>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => banUser(selectedUser.userUID)} color="primary">
//             Ban User
//           </Button>
//           <Button onClick={() => setDialogOpen(false)} color="secondary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Dialog open={unbanDialogOpen} onClose={() => setUnbanDialogOpen(false)} fullWidth>
//         <DialogTitle>Unban User</DialogTitle>
//         <DialogContent>
//           {selectedUser && (
//             <>
//               <Typography>
//                 <b>{selectedUser.isBanned ? 'User is currently banned.' : 'User is not banned.'}</b><br></br>
//             </Typography>
//               <Typography>Username: {selectedUser.username}</Typography>
//               <Typography>Email: {selectedUser.email}</Typography>
//               <Typography>Time Spent: {selectedUser.timeSpent} Minutes</Typography>
//               <Typography>
//                     Banned Until: 
//                     {selectedUser.banUntil ? 
//                         new Date(selectedUser.banUntil._seconds * 1000).toLocaleDateString("en-US") + " " +
//                         new Date(selectedUser.banUntil._seconds * 1000).toLocaleTimeString("en-US") :
//                         ' N/A'}
//                 </Typography>
//               <Typography>Ban Reason: {selectedUser.banReason}</Typography>
//               <Typography>Times Banned: {selectedUser.timesBanned}</Typography>
//               <Typography>Risk Score: {selectedUser.riskScore}</Typography>
//               <Typography>Member Status: {membershipStatus(selectedUser.member)}</Typography>
//             </>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => unbanUser(selectedUser.userUID)} color="primary">
//             Unban User
//           </Button>
//           <Button onClick={() => setUnbanDialogOpen(false)} color="secondary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Dialog open={chatLogsDialogOpen} onClose={() => setChatLogsDialogOpen(false)} fullWidth>
//         <DialogTitle>Chat Logs</DialogTitle>
//         <DialogContent>
//           {chatLogs.length > 0 ? (
//             chatLogs.map((log, index) => (
//               <Box key={index} className="chat-log">
//                 <Typography variant="body2">
//                   <strong>{log.username}:</strong> {log.msg}
//                 </Typography>
//                 <Typography variant="caption" color="textSecondary">
//                  {"Room: " + log.room} 
//                 </Typography><br></br>
//                 <Typography variant="caption" color="textSecondary">
//                  {new Date(log.createdAt._seconds * 1000).toLocaleDateString("en-US") + " "}
//                  {new Date(log.createdAt._seconds * 1000).toLocaleTimeString("en-US")}
//                 </Typography>
//               </Box>
//             ))
//           ) : (
//             <Typography>No chat logs found</Typography>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setChatLogsDialogOpen(false)} color="secondary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <UserDetail
//         user={selectedUser}
//         open={userDetailOpen}
//         onClose={() => setUserDetailOpen(false)}
//       />
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         message={snackBarMessage}
//         action={
//           <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         }
//       />
//       <FlaggedChats />
//       <Box mt={4}>
//         <Typography variant="h5" gutterBottom>
//           Live Chat Logs
//         </Typography>
//         <Paper className="live-chat-logs">
//             <div className="chat-log-messages">
//                 <TableBody>
//                     {liveChatMessages
//                         .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
//                         .map((msg, index) => (
//                             <TableRow key={msg.uid} className="chat-log-row">
//                                 <TableCell>
//                                     <div key={index} className={`chat-log-message ${msg.flagged ? 'red-text' : ''}`}>
//                                         <strong>{msg.username} [{msg.room}]</strong>: {msg.message} <span className="timestamp">{formatDate(msg.createdAt)}</span>
//                                     </div>
//                                 </TableCell>
//                                 <TableCell align="right" className="icon-buttons">
//                                     <IconButton onClick={() => viewBanUserInfo(msg.uid)}>
//                                         <GavelIcon />
//                                     </IconButton>
//                                     <IconButton onClick={() => viewChatLogs(msg.uid)}>
//                                         <ChatIcon />
//                                     </IconButton>
//                                     <IconButton onClick={() => viewUserDetail(msg.uid)}>
//                                         <PersonIcon />
//                                     </IconButton>
//                                 </TableCell>
//                             </TableRow>
//                         ))}
//                 </TableBody>
//             </div>
//         </Paper>
//       </Box>
//       <SupportTickets
//         supportTickets={supportTickets}
//         loading={loading}
//         error={ticketError}
//         closeSupportTicket={closeSupportTicket}
//         formatDate={formatDate}
//         />
//     </Container>
//   );
// };

// export default AdminPanel;