// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.terms-container h1 {
    text-align: center;
    margin-bottom: 20px;
}

.terms-container h2 {
    margin-top: 20px;
}

.terms-container p {
    line-height: 1.6;
}

.terms-container ul {
    list-style-type: disc;
    margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/TermsOfService.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB","sourcesContent":[".terms-container {\n    max-width: 800px;\n    margin: auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    margin-top: 20px;\n    margin-bottom: 20px;\n    border-radius: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.terms-container h1 {\n    text-align: center;\n    margin-bottom: 20px;\n}\n\n.terms-container h2 {\n    margin-top: 20px;\n}\n\n.terms-container p {\n    line-height: 1.6;\n}\n\n.terms-container ul {\n    list-style-type: disc;\n    margin-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
