import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputBase,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    TextField,
    Typography,
  } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';
import ChatIcon from '@mui/icons-material/Chat';
import PersonIcon from '@mui/icons-material/Person';
import { getAuthToken } from '../utils/firebaseUtils';

const FlaggedChats = ({ viewBanUserInfo, viewChatLogs, viewUserDetail }) => {
    const [flaggedChats, setFlaggedChats] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        fetchFlaggedChats(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const fetchFlaggedChats = async (page, rowsPerPage) => {
        try {
            const token = await getAuthToken();
            const response = await axios.get(`/api/api/admin/flagged-chats?page=${page}&limit=${rowsPerPage}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setFlaggedChats(response.data.flaggedChats);
        } catch (error) {
            console.error('Error fetching flagged chats:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box mt={4}>
            <Typography variant="h5" gutterBottom>
                Flagged Chats
            </Typography>
            <Paper className="flagged-chat-logs">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell>Message</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>UID</TableCell>
                            <TableCell>Ban Status</TableCell>
                            <TableCell align="right">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {flaggedChats.map((chat) => (
                            <TableRow key={chat.uid}>
                                <TableCell>{chat.username}</TableCell>
                                <TableCell>{chat.msg}</TableCell>
                                <TableCell>{new Date(chat.createdAt._seconds * 1000).toLocaleDateString("en-US") + " "}
                                {new Date(chat.createdAt._seconds * 1000).toLocaleTimeString("en-US")}</TableCell>
                                <TableCell>{chat.uid}</TableCell>
                                <TableCell>{chat.ban ? 'Banned' : 'Not Banned'}</TableCell>
                                <TableCell align="right">
                                    <IconButton onClick={() => viewBanUserInfo(chat.uid)}>
                                        <GavelIcon />
                                    </IconButton>
                                    <IconButton onClick={() => viewChatLogs(chat.uid)}>
                                        <ChatIcon />
                                    </IconButton>
                                    <IconButton onClick={() => viewUserDetail(chat.uid)}>
                                        <PersonIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={100} // You need to replace this with the total count of flagged chats
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default FlaggedChats;