import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuthToken } from '../../../utils/firebaseUtils';
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Snackbar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AddFurnitureItem = () => {
  const [formData, setFormData] = useState({
    available: true,
    description: '',
    id: '',
    itemType: '',
    member: '',
    preview: true,
    price: '',
    type: '',
    xOffset: '',
    yOffset: '',
    dirs: '',
    scale: ''
  });
  const [items, setItems] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [currentDocId, setCurrentDocId] = useState(null);

  useEffect(() => {
    fetchFurnitureItems();
  }, []);

  const fetchFurnitureItems = async () => {
    try {
      const token = await getAuthToken();
      const response = await axios.get('/api/api/admin/furniture-items', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setItems(response.data);
    } catch (error) {
      console.error('Error fetching furniture items:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Convert certain fields to their appropriate types
    const convertedValue = 
      (name === 'available' || name === 'preview') ? (value === 'True') :
      ['dirs', 'id', 'member'].includes(name) ? (value ? parseInt(value, 10) : '') :
      ['price', 'xOffset', 'yOffset', 'scale'].includes(name) ? (value ? parseFloat(value) : '') :
      value;

    setFormData({ ...formData, [name]: convertedValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await getAuthToken();
    const headers = { headers: { Authorization: `Bearer ${token}` } };

    if (editMode) {
      try {
        await axios.put(`/api/api/admin/furniture-items/${currentDocId}`, formData, headers);
        setSnackbarMessage('Item updated successfully!');
        setEditMode(false);
        setCurrentDocId(null);
      } catch (error) {
        console.error('Error updating item:', error);
        setSnackbarMessage('Failed to update item');
      }
    } else {
      try {
        await axios.post('/api/api/admin/furniture-items', formData, headers);
        setSnackbarMessage('Item added successfully!');
      } catch (error) {
        console.error('Error adding item:', error);
        setSnackbarMessage('Failed to add item');
      }
    }
    setSnackbarOpen(true);
    fetchFurnitureItems();
    resetForm();
  };

  const handleEdit = (item) => {
    // Ensure that all fields are pulled in exactly as they are in the database
    setFormData({
      available: item.available !== undefined ? item.available === 'True' || item.available === true : true,
      description: item.description || '',
      id: item.id !== undefined ? item.id : '',
      itemType: item.itemType || '',
      member: item.member !== undefined ? item.member : '',
      preview: item.preview !== undefined ? item.preview === 'True' || item.preview === true : true,
      price: item.price !== undefined ? item.price : '',
      type: item.type || '',
      xOffset: item.xOffset !== undefined ? item.xOffset : '',
      yOffset: item.yOffset !== undefined ? item.yOffset : '',
      dirs: item.dirs !== undefined ? item.dirs : '',
      scale: item.scale !== undefined ? item.scale : ''
    });
    setEditMode(true);
    setCurrentDocId(item.docId);
  };

  const resetForm = () => {
    setFormData({
      available: true,
      description: '',
      id: '',
      itemType: '',
      member: '',
      preview: true,
      price: '',
      type: '',
      xOffset: '',
      yOffset: '',
      dirs: '',
      scale: ''
    });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h5" gutterBottom>
        {editMode ? 'Edit Furniture Item' : 'Add New Furniture Item'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Explanation and Form fields */}
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Is the item available? (Defaults to true if not specified)
            </Typography>
            <Select
              fullWidth
              name="available"
              value={formData.available ? 'True' : 'False'}
              onChange={handleChange}
            >
              <MenuItem value="True">True</MenuItem>
              <MenuItem value="False">False</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              name="description"
              label="Description"
              value={formData.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="id"
              label="ID"
              value={formData.id}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="itemType"
              label="Item Type"
              value={formData.itemType}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="member"
              label="Member"
              value={formData.member}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Is the item in preview mode? (Defaults to true if not specified)
            </Typography>
            <Select
              fullWidth
              name="preview"
              value={formData.preview ? 'True' : 'False'}
              onChange={handleChange}
            >
              <MenuItem value="True">True</MenuItem>
              <MenuItem value="False">False</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="price"
              label="Price"
              value={formData.price}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="type"
              label="Type"
              value={formData.type}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="xOffset"
              label="X Offset"
              value={formData.xOffset}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="yOffset"
              label="Y Offset"
              value={formData.yOffset}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="dirs"
              label="Dirs"
              value={formData.dirs}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="scale"
              label="Scale"
              value={formData.scale}
              onChange={handleChange}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit">
              {editMode ? 'Update Item' : 'Add Item'}
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Display current furniture items */}
      <Typography variant="h6" gutterBottom>
        Current Furniture Items
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Available</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
              <TableRow key={item.docId}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.name || ''}</TableCell>
                <TableCell>{item.type || ''}</TableCell>
                <TableCell>{item.price !== undefined ? item.price : ''}</TableCell>
                <TableCell>{item.available ? 'True' : 'False'}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleEdit(item)}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default AddFurnitureItem;