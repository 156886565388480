// src/pages/CheckoutPage.js
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/CheckoutForm';
import { stripePromise } from '../firebaseConfig';

const CheckoutPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <div>
        <CheckoutForm />
      </div>
    </Elements>
  );
};

export default CheckoutPage;