// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#footer {
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    text-align: center;
    box-shadow: 0px -1px 8px rgba(50, 50, 50, 0.49);
  }
  
  #footer ul {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }
  
  #footer li {
    margin: 0 10px;
  }
  
  #footer li a {
    color: #828282;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  #footer li a:hover {
    color: #000;
  }`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,+CAA+C;EACjD;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,UAAU;IACV,SAAS;EACX;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,2BAA2B;EAC7B;;EAEA;IACE,WAAW;EACb","sourcesContent":["\n#footer {\n    width: 100%;\n    background-color: #fff;\n    padding: 10px 0;\n    text-align: center;\n    box-shadow: 0px -1px 8px rgba(50, 50, 50, 0.49);\n  }\n  \n  #footer ul {\n    list-style: none;\n    display: flex;\n    justify-content: center;\n    flex-wrap: wrap;\n    padding: 0;\n    margin: 0;\n  }\n  \n  #footer li {\n    margin: 0 10px;\n  }\n  \n  #footer li a {\n    color: #828282;\n    text-decoration: none;\n    transition: color 0.3s ease;\n  }\n  \n  #footer li a:hover {\n    color: #000;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
