import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import UAParser from 'ua-parser-js';
import './RegisterPage.css';


const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [referralId, setReferralId] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [consentTerms, setConsentTerms] = useState(false);
    const [consentMarketing, setConsentMarketing] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if(urlParams.get('referralId')) {
            setReferralId(urlParams.get('referralId'));
        }
    }, [])
    const handleRegister = async (event) => {
      event.preventDefault();
      if (!consentTerms) {
        setError('You must agree to the terms of service.');
        return;
      }
      setLoading(true);
      setError('');
      setSuccessMessage('');
      const specialCharRegex = /[^a-zA-Z0-9]/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
      if (!emailRegex.test(email)) {
        setError('Please enter a valid email address.');
        return;
      }
  
      if (username.length < 3 || username.length > 12) {
        setError('Username must be between 3 and 12 characters.');
        return;
      }
  
      if (specialCharRegex.test(username)) {
        setError('Username must not contain special characters.');
        return;
      }
  
      if (password.length < 8) {
        setError('Password must be at least 8 characters long.');
        return;
      }
  
      try {
        const parser = new UAParser();
        const deviceInfo = parser.getResult();
  
        const response = await axios.post('/api/api/register', {
          email,
          username,
          password,
          deviceInfo,
          referralId,
          consentTerms,
          consentMarketing
        });
        setLoading(false);
        setError('');
        setSuccessMessage('Registration successful! Please verify your email address, a verification link has been sent.');
        
      } catch (error) {
        setLoading(false);
        console.error('Registration failed:', error);
        setError(error.response?.data?.message || 'Registration failed. Please try again.');
      }
    };
  

  const getDeviceInfo = () => {
    const parser = new UAParser();
    const result = parser.getResult();
    return {
      device: result.device.type || 'desktop',
      os: result.os.name + ' ' + result.os.version,
      browser: result.browser.name + ' ' + result.browser.version,
    };
  };

  return (
    <div className="register-container">
            <form onSubmit={handleRegister} className="register-form">
                {loading && (
            <div className="loading-overlay">
                <div className="spinner"></div>
            </div>
            )}
            <h2>Register</h2>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
            />
            <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
            <b>You'll start with extra Waffle Cash using a referral code!</b><br></br>
            <input
                type="text"
                placeholder="Referral Code (Optional)"
                value={referralId}
                onChange={(e) => setReferralId(e.target.value)}
                required
            />
            <div className="checkbox-container">
                <label className="pretty-checkbox">
                    <input
                        type="checkbox"
                        checked={consentTerms}
                        onChange={() => setConsentTerms(!consentTerms)}
                    />
                    <span className="custom-checkbox"></span>
                    I agree to the  <a href="/terms-of-service" target="_blank" rel="noopener noreferrer">Terms of Service</a> & <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </label>
            </div>
            <div className="checkbox-container">
                <label className="pretty-checkbox">
                    <input
                        type="checkbox"
                        checked={consentMarketing}
                        onChange={() => setConsentMarketing(!consentMarketing)}
                    />
                    <span className="custom-checkbox"></span>
                    I agree to receive marketing updates about Waffle Island
                </label>
            </div>
            <button type="submit">Register</button>
            {error && <div className="error-message">{error}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            </form>
        </div>
  );
};

export default RegisterPage;