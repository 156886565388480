import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './CheckoutForm.css';
import Login from './Login';
import { useAuth } from '../context/AuthContext';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { currentUser } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [planId, setPlanId] = useState('');
  const [planDescription, setPlanDescription] = useState('');

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email);
      console.log(currentUser.email);
    }
  }, [currentUser]);

  const handlePlanChange = (event) => {
    const selectedPlanId = event.target.value;
    setPlanId(selectedPlanId);

    let description = '';
    switch (selectedPlanId) {
      case 'price_1PXOchC9d2e8ToFd3TDBnkdD':
        description = 'Basic Plan - $5/month: Includes basic features and access to the community.';
        break;
      case 'price_1PXUVOC9d2e8ToFd0L6HzZNx':
        description = 'Standard Plan - $10/month: Includes all basic features plus premium content.';
        break;
      case 'price_1PXUW0C9d2e8ToFdcZ1kFTjY':
        description = 'Premium Plan - $20/month: Includes all standard features plus one-on-one support.';
        break;
      default:
        description = '';
    }
    setPlanDescription(description);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');
    if (!stripe || !elements || !currentUser || !planId) {
      setErrorMessage('Please login and select a plan before subscribing.');
      return;
    }

    setLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      console.error(error);
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      const { id } = paymentMethod;
      try {
        const token = await currentUser.getIdToken();
        const uid = currentUser.uid;
        const response = await axios.post('https://dev.waffleisland.co/api/api/subscribe', 
          { 
            email,
            uid,
            paymentMethodId: id,
            planId
          }, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.subscriptionId) {
          setSuccessMessage('Subscription successful! Your subscription ID is: ' + response.data.subscriptionId + ' You can visit your account dashboard or refresh your app to view your membership details. Thank you for being a loyal member, have fun!');
          setErrorMessage('');
        }
        setLoading(false);
      } catch (error) {
        console.error('Frontend: Subscription failed:', error);
        setErrorMessage('Subscription failed. Please try again. Error: ' + error.response.data.error.message);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      {!currentUser ? (
        <Login />
      ) : (
        <div>
          <div className="sales-info">
            <h2>Unlock Exclusive Features with Waffle Island Membership!</h2><br></br>
            <p>Join the Waffle Island community and enhance your gaming experience with our membership plans:</p><br></br>
            <ul>
              <li>
                <strong>Basic Plan - $5/month</strong>: Enjoy basic features and gain access to the community. Start your adventure with essential tools and resources.
              </li>
              <li>
                <strong>Standard Plan - $10/month</strong>: All basic features plus premium content. Dive deeper into the world of Waffle Island with exclusive in-game items and perks.
              </li>
              <li>
                <strong>Premium Plan - $20/month</strong>: All standard features plus one-on-one support. Get the ultimate Waffle Island experience with personalized assistance and VIP treatment.
              </li>
            </ul>
            <p>Select a plan that suits you best and become a part of our vibrant and growing community today!</p>
          </div>
          <form onSubmit={handleSubmit} className="form-container">
            <div className="plan-description-box">
              {planDescription || "Select a plan to see its description."}
            </div>
            <select onChange={handlePlanChange} value={planId} required>
              <option value="" disabled>Select a plan</option>
              <option value="price_1PXOchC9d2e8ToFd3TDBnkdD">Basic Plan - $5/month</option>
              <option value="price_1PXUVOC9d2e8ToFd0L6HzZNx">Standard Plan - $10/month</option>
              <option value="price_1PXUW0C9d2e8ToFdcZ1kFTjY">Premium Plan - $20/month</option>
            </select>
            <CardElement className="card-element" />
            <button type="submit" className="submit-button" disabled={!stripe || loading}>
              {loading ? 'Processing...' : 'Subscribe'}
            </button>
            {loading && <div className="loading-spinner">Loading...</div>}
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
          </form>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;


// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import axios from 'axios';
// import './CheckoutForm.css';
// import Login from './Login';
// import { useAuth } from '../context/AuthContext';

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const { currentUser } = useAuth();
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [email, setEmail] = useState('');
//   const [planId, setPlanId] = useState(''); // State to store the selected plan ID
//   const [planDescription, setPlanDescription] = useState(''); // State to store the selected plan description

//   const handlePlanChange = (event) => {
//     const selectedPlanId = event.target.value;
//     setPlanId(selectedPlanId);

//     // Update the plan description based on the selected plan
//     let description = '';
//     switch (selectedPlanId) {
//       case 'price_1PXOchC9d2e8ToFd3TDBnkdD':
//         description = 'Basic Plan - $5/month: Includes basic features and access to the community.';
//         break;
//       case 'price_1PXUVOC9d2e8ToFd0L6HzZNx':
//         description = 'Standard Plan - $10/month: Includes all basic features plus premium content.';
//         break;
//       case 'price_1PXUW0C9d2e8ToFdcZ1kFTjY':
//         description = 'Premium Plan - $20/month: Includes all standard features plus one-on-one support.';
//         break;
//       default:
//         description = '';
//     }
//     setPlanDescription(description);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements || !currentUser || !planId) {
//       setErrorMessage('Please login and select a plan before subscribing.');
//       return;
//     }

//     setLoading(true); // Set loading state to true

//     const cardElement = elements.getElement(CardElement);

//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: cardElement,
//     });

//     if (error) {
//       console.error(error);
//       setErrorMessage(error.message);
//       setLoading(false); // Set loading state to false on error
//     } else {
//       const { id } = paymentMethod;
//       try {
//         const token = await currentUser.getIdToken();
//         const uid = currentUser.uid;
//         const response = await axios.post('https://dev.waffleisland.co/api/api/subscribe', 
//           { 
//             email,
//             uid,
//             paymentMethodId: id,
//             planId // Include planId in the request body
//           }, 
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.data.subscriptionId) {
//           setSuccessMessage('Subscription successful! Your subscription ID is: ' + response.data.subscriptionId);
//           setErrorMessage('');
//         }
//         setLoading(false); // Set loading state to false after successful request
//       } catch (error) {
//         console.error('Frontend: Subscription failed:', error);
//         setErrorMessage('Subscription failed. Please try again. Error: ' + error.message);
//         setLoading(false); // Set loading state to false on error
//       }
//     }
//   };

//   return (
//     <div>
//       {!currentUser ? (
//         <Login />
//       ) : (
//         <form onSubmit={handleSubmit} className="form-container">
//           <div className="plan-description-box">
//             {planDescription || "Select a plan to see its description."}
//           </div>
//           <select onChange={handlePlanChange} value={planId} required>
//             <option value="" disabled>Select a plan</option>
//             <option value="price_1PXOchC9d2e8ToFd3TDBnkdD">Basic Plan - $5/month</option>
//             <option value="price_1PXUVOC9d2e8ToFd0L6HzZNx">Standard Plan - $10/month</option>
//             <option value="price_1PXUW0C9d2e8ToFdcZ1kFTjY">Premium Plan - $20/month</option>
//           </select>
//           <CardElement className="card-element" />
//           <button type="submit" className="submit-button" disabled={!stripe || loading}>
//             {loading ? 'Processing...' : 'Subscribe'}
//           </button>
//           {loading && <div className="loading-spinner">Loading...</div>}
//           {errorMessage && <div className="error-message">{errorMessage}</div>}
//           {successMessage && <div className="success-message">{successMessage}</div>}
//         </form>
//       )}
//     </div>
//   );
// };

// export default CheckoutForm;

// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import axios from 'axios';
// import './CheckoutForm.css';
// import Login from './Login';

// const CheckoutForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [email, setEmail] = useState('');
//   const [user, setUser] = useState(null);
//   const [planId, setPlanId] = useState(''); // State to store the selected plan ID
//   const [planDescription, setPlanDescription] = useState(''); // State to store the selected plan description

//   const handleLogin = (user) => {
//     setUser(user);
//     setEmail(user.email);
//   };

//   const handlePlanChange = (event) => {
//     const selectedPlanId = event.target.value;
//     setPlanId(selectedPlanId);

//     // Update the plan description based on the selected plan
//     let description = '';
//     switch (selectedPlanId) {
//       case 'price_1PXOchC9d2e8ToFd3TDBnkdD':
//         description = 'Basic Plan - $5/month: Includes basic features and access to the community.';
//         break;
//       case 'price_1PXUVOC9d2e8ToFd0L6HzZNx':
//         description = 'Standard Plan - $10/month: Includes all basic features plus premium content.';
//         break;
//       case 'price_1PXUW0C9d2e8ToFdcZ1kFTjY':
//         description = 'Premium Plan - $20/month: Includes all standard features plus one-on-one support.';
//         break;
//       default:
//         description = '';
//     }
//     setPlanDescription(description);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements || !user || !planId) {
//       setErrorMessage('Please login and select a plan before subscribing.');
//       return;
//     }

//     setLoading(true); // Set loading state to true

//     const cardElement = elements.getElement(CardElement);

//     const { error, paymentMethod } = await stripe.createPaymentMethod({
//       type: 'card',
//       card: cardElement,
//     });

//     if (error) {
//       console.error(error);
//       setErrorMessage(error.message);
//       setLoading(false); // Set loading state to false on error
//     } else {
//       const { id } = paymentMethod;
//       try {
//         const token = await user.getIdToken();
//         const uid = user.uid;
//         const response = await axios.post('https://dev.waffleisland.co/api/api/subscribe', 
//           { 
//             email,
//             uid,
//             paymentMethodId: id,
//             planId // Include planId in the request body
//           }, 
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );

//         if (response.data.subscriptionId) {
//           setSuccessMessage('Subscription successful! Your subscription ID is: ' + response.data.subscriptionId);
//           setErrorMessage('');
//         }
//         setLoading(false); // Set loading state to false after successful request
//       } catch (error) {
//         console.error('Frontend: Subscription failed:', error);
//         setErrorMessage('Subscription failed. Please try again. Error: ' + error.message);
//         setLoading(false); // Set loading state to false on error
//       }
//     }
//   };

//   return (
//     <div>
//       {!user ? (
//         <Login onLogin={handleLogin} />
//       ) : (
//         <form onSubmit={handleSubmit} className="form-container">
//           <div className="plan-description-box">
//             {planDescription || "Select a plan to see its description."}
//           </div>
//           <select onChange={handlePlanChange} value={planId} required>
//             <option value="" disabled>Select a plan</option>
//             <option value="price_1PXOchC9d2e8ToFd3TDBnkdD">Basic Plan - $5/month</option>
//             <option value="price_1PXUVOC9d2e8ToFd0L6HzZNx">Standard Plan - $10/month</option>
//             <option value="price_1PXUW0C9d2e8ToFdcZ1kFTjY">Premium Plan - $20/month</option>
//           </select>
//           <CardElement className="card-element" />
//           <button type="submit" className="submit-button" disabled={!stripe || loading}>
//             {loading ? 'Processing...' : 'Subscribe'}
//           </button>
//           {loading && <div className="loading-spinner">Loading...</div>}
//           {errorMessage && <div className="error-message">{errorMessage}</div>}
//           {successMessage && <div className="success-message">{successMessage}</div>}
//         </form>
//       )}
//     </div>
//   );
// };

// export default CheckoutForm;