import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './VerifyEmail.css';

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`/api/api/verify-email?token=${token}`);
        setMessage(response.data);
      } catch (error) {
        setMessage(error.response ? error.response.data : 'Verification failed. Please try again.');
      }
    };

    if (token) {
      verifyEmail();
    }

    const timer = setTimeout(() => {
      navigate('/');
    }, 4000);

    return () => clearTimeout(timer);
  }, [token, navigate]);

  return (
    <div className="verify-container">
      <div className="verify-message">
        <h2>{message}</h2>
        <p>Redirecting to the home page...</p>
      </div>
    </div>
  );
};

export default VerifyEmail;