import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const SupportTickets = ({ supportTickets, loading, error, closeSupportTicket, formatDate }) => {
  return (
    <Box className="support-tickets">
      <Typography variant="h5" gutterBottom>
        Support Tickets
      </Typography>
      {loading && <CircularProgress className="loading-spinner" />}
      {error && <Typography color="error">{error}</Typography>}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Issue</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {supportTickets.length > 0 ? (
              supportTickets.map((ticket) => (
                <TableRow key={ticket.id}>
                  <TableCell>{ticket.username}</TableCell>
                  <TableCell>{ticket.email}</TableCell>
                  <TableCell>{ticket.issue}</TableCell>
                  <TableCell>{ticket.description}</TableCell>
                  <TableCell>{new Date(ticket.createdAt._seconds * 1000).toLocaleDateString("en-US") + " "}
                  {new Date(ticket.createdAt._seconds * 1000).toLocaleTimeString("en-US")}</TableCell>
                  <TableCell align="right">
                    <Button onClick={() => closeSupportTicket(ticket.id)} color="primary">
                      Mark as Closed
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No open support tickets found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SupportTickets;