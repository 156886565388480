// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AboutUs.css */

.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
}

.about-container h2 {
    font-size: 1.8em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #555;
}

.about-container p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
}

.about-container ul {
    margin: 20px 0;
    padding-left: 20px;
}

.about-container ul li {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
}

@media (max-width: 768px) {
    .about-container {
        padding: 15px;
    }

    .about-container h1 {
        font-size: 2em;
    }

    .about-container h2 {
        font-size: 1.5em;
    }

    .about-container p, .about-container ul li {
        font-size: 1em;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/AboutUs.css"],"names":[],"mappings":"AAAA,gBAAgB;;AAEhB;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,cAAc;IAClB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,cAAc;IAClB;AACJ","sourcesContent":["/* AboutUs.css */\n\n.about-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    margin-top: 30px;\n    margin-bottom: 30px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.about-container h1 {\n    font-size: 2.5em;\n    margin-bottom: 20px;\n    color: #333;\n}\n\n.about-container h2 {\n    font-size: 1.8em;\n    margin-top: 20px;\n    margin-bottom: 10px;\n    color: #555;\n}\n\n.about-container p {\n    font-size: 1.2em;\n    line-height: 1.6;\n    color: #666;\n}\n\n.about-container ul {\n    margin: 20px 0;\n    padding-left: 20px;\n}\n\n.about-container ul li {\n    font-size: 1.2em;\n    line-height: 1.6;\n    color: #666;\n}\n\n@media (max-width: 768px) {\n    .about-container {\n        padding: 15px;\n    }\n\n    .about-container h1 {\n        font-size: 2em;\n    }\n\n    .about-container h2 {\n        font-size: 1.5em;\n    }\n\n    .about-container p, .about-container ul li {\n        font-size: 1em;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
